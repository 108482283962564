<template>
 <div>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 60 60">
  <defs>
    <linearGradient id="linear-gradienttikie" x1="0.792" x2="0.455" y2="0.616" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#4843de"/>
      <stop offset="1" stop-color="#4a4886"/>
    </linearGradient>
  </defs>
  <g id="Tikkie" transform="translate(-450 -288)">
    <rect id="Rectangle_21" data-name="Rectangle 21" style="fill: #fff;" width="60" height="60" transform="translate(450 288)"/>
    <g id="tikkie-3" style="fill:#529696" transform="translate(450.412 40.758)">
      <circle id="Ellipse_127" data-name="Ellipse 127" style="fill: url(#linear-gradienttikie);" cx="18" cy="18" r="18" transform="translate(11.588 259.242)"/>
      <path id="Path_250" data-name="Path 250" d="M1349.573,800.7a6.109,6.109,0,0,0-6.107,6.118,5.98,5.98,0,0,0,.856,3.106v.181a2.919,2.919,0,0,1-1.522,2.743,5.736,5.736,0,0,0,3.289-.986c.011,0,.024-.015.038-.015s.027.015.039.015h0a5.536,5.536,0,0,0,3.4,1.054,6.108,6.108,0,1,0,0-12.216" transform="translate(-1312.522 -529.453)"/>
      <g id="Group_103" data-name="Group 103" transform="translate(15.724 273.761)">
        <path id="Path_248" data-name="Path 248" style="fill: #fff" d="M1534.655,950.375c-.24,0-.356-.106-.356-.346,0-.205.116-.328.356-.328h.009v-.169c0-.1,0-.205.009-.31h-.019c-.24,0-.356-.108-.356-.346,0-.205.116-.328.356-.328h.116a3.077,3.077,0,0,1,.764-1.4,2.949,2.949,0,0,1,2.239-.942,2.748,2.748,0,0,1,1.377.328c.4.213.6.469.6.765a.681.681,0,0,1-.587.737,1.083,1.083,0,0,1-.587-.2,1.461,1.461,0,0,0-.8-.213,1.492,1.492,0,0,0-1.2.524,1.913,1.913,0,0,0-.25.4h1.359a.316.316,0,0,1,.356.328.313.313,0,0,1-.356.346h-1.529c-.009.106-.018.213-.018.31v.169h1.031a.313.313,0,0,1,.346.328.31.31,0,0,1-.346.346h-.89a1.817,1.817,0,0,0,.293.506,1.492,1.492,0,0,0,1.2.524,1.52,1.52,0,0,0,.8-.205,1.04,1.04,0,0,1,.587-.2.684.684,0,0,1,.587.728c-.009.3-.2.559-.6.781a2.844,2.844,0,0,1-1.377.321,2.943,2.943,0,0,1-2.239-.943,3.215,3.215,0,0,1-.79-1.511h-.086Z" transform="translate(-1510.235 -946.02)"/>
        <path id="Path_249" data-name="Path 249" style="fill: #fff" d="M223.529,937.863h-.963a.732.732,0,1,1,0-1.463h3.506a.732.732,0,1,1,0,1.463h-.952v4.556c0,.678-.334.963-.8.963s-.8-.284-.8-.963Zm4.782-1.463c.462,0,.8.284.8.963v5.057c0,.678-.334.963-.8.963s-.8-.284-.8-.963V937.45c0-.794.325-1.051.8-1.051m3.537,4.234v1.786c0,.678-.334.963-.8.963s-.8-.284-.8-.963v-5.057c0-.678.325-.963.8-.963s.8.284.8.963v1.659l2.13-2.169c.462-.51.893-.491,1.2-.246a.792.792,0,0,1-.049,1.218l-2.131,2,2.279,2.121a1.034,1.034,0,0,1,.383.688.79.79,0,0,1-.2.53.72.72,0,0,1-.53.216,1.015,1.015,0,0,1-.678-.363Zm6.344,0v1.786c0,.678-.334.963-.8.963s-.8-.284-.8-.963v-5.057c0-.678.325-.963.8-.963s.8.284.8.963v1.659l2.131-2.169c.462-.51.895-.491,1.2-.246a.793.793,0,0,1-.05,1.218l-2.131,2,2.279,2.121a1.034,1.034,0,0,1,.383.688.79.79,0,0,1-.2.53.72.72,0,0,1-.53.216,1.015,1.015,0,0,1-.678-.363Zm5.548-4.234c.462,0,.8.284.8.963v5.057c0,.678-.334.963-.8.963s-.8-.284-.8-.963V937.45c0-.794.325-1.051.8-1.051" transform="translate(-221.8 -936.4)"/>
      </g>
    </g>
  </g>
</svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>